import React from "react"

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
class QuintaSecao extends React.Component {
    render() {
        const { props } = this;
        return (
            <div className='row'>
                {
                    ((props.PurchaseEnable.toUpperCase() == "SIM")
                    ?
                        ((props.HabilitarPaginaVendas.toUpperCase() == "SIM")
                            ?
                                <Link to="comprar-curso" className='col-12 no-decoration' style={{ textAlign: 'center' }}>
                                <div className='container adquirir-curso' style={{ fontFamily: 'Fira Sans, sans-serif', fontSize: '14px' }}>
                                {props.TextoBotao}
                                </div>
                                </Link>
                            : 
                                <a color="primary" href="https://api.whatsapp.com/send?1=pt_BR&phone=5519984458777&text=Gostaria%20de%20me%20informar%20melhor%20sobre%20o%20curso" target="_blank" rel="noopener noreferrer" className='col-12 no-decoration' style={{ textAlign: 'center' }} >
                                <div className='container adquirir-curso' style={{ fontFamily: 'Fira Sans, sans-serif', fontSize: '14px' }}>
                                {props.TextoBotao}
                                </div>
                                </a>
                        )
                        :
                            <div className='col-12' style={{ textAlign: 'center' }}>
                                <div className='container' style={{ marginTop: '10px' }}>
                                {/* <h2 style={{ fontFamily: 'Fira sans', color: '#fff' }}>
                                        Estamos em manutenção. Em breve, você poderá a voltar assistir nossas aulas!
                                        <br /><br />
                                    </h2> */}

                                    <p style={{ fontFamily: 'Fira sans', color: '#fff' }}>
                                        Quer saber maiores detalhes sobre o valor e as formas de pagamento? Clique no botão abaixo para um atendimento pessoal!
                                    </p>
                                    <Button color="primary" variant="contained" >
                                        <a color="primary" href="https://api.whatsapp.com/send?1=pt_BR&phone=5519984458777&text=Gostaria%20de%20me%20informar%20melhor%20sobre%20o%20curso" target="_blank" rel="noopener noreferrer" style={{ fontFamily: 'Fira sans', color: '#5E610B', fontSize: '1.5rem' }} >
                                            Clique Aqui
                                        </a>
                                    </Button>
                                </div> </div>
                    )
                        
                }
            </div>
        );
    }
}

export default QuintaSecao;