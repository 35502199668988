import Axios from 'axios';
import React, { Component } from 'react';
import IndexBranch from "./IndexBrach";
import arvore from '../../assets/img/ARVORE-BRANCA.png';

const indexTreeInfos = [
    {
        branchClass: 'galho1',
        title: 'FERRAMENTAS MELÓDICAS',
        subTitle: 'Recursos melódicos para improvisação e composição'
    },
    {
        branchClass: 'galho2',
        title: 'LITERATURA PIANÍSTICA',
        subTitle: 'Aplicando conceitos da música clássica na música popular'
    },
    {
        branchClass: 'galho3',
        title: 'FERRAMENTAS RÍTMICAS',
        subTitle: 'Recursos rítmicos para improvisação e composição'
    },
    {
        branchClass: 'galho4',
        title: 'REPERTÓRIO',
        subTitle: 'Estudos práticos do repertório nacional e internacional'
    },
    {
        branchClass: 'galho5',
        title: 'FERRAMENTAS HARMÔNICAS',
        subTitle: 'Recursos harmônicos para improvisação e composição'
    },
    {
        branchClass: 'galho6',
        title: 'EVALDO SOARES',
        subTitle: 'Uma área exclusiva de arranjos do nosso mestre'
    },
    {
        branchClass: 'galho7',
        title: 'ESCALAS',
        subTitle: 'Aplicações harmônicas e melódicas'
    },
    {
        branchClass: 'galho8',
        title: 'FERRAMENTAS BÁSICAS',
        subTitle: 'Os princípios na formação do pianista'
    }
]

class TreeIndexSemDinamica extends Component {

    render() {
        return (
            <div className='col-md-8 col-sm-12 col-12' style={{ padding: '0px' }}>
                <div className='arvorenew'>
                    <img src={arvore} className="imgarvore" alt="" />
                    {
                        indexTreeInfos.map(branch => {
                            return (
                                <IndexBranch
                                    key={branch.branchClass}
                                    branch={branch.branchClass}
                                    title={branch.title}
                                    subTitle={branch.subTitle} />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default TreeIndexSemDinamica;