import axios from 'axios';

export async function Get(url, headers) {

    if (headers !== null && headers !== undefined) {
        return await axios.get(url, headers).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.get(url).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}


export async function GetComToken(url, headers) {
    try {
        if (headers !== null && headers !== undefined) {
            const response = await axios.get(url, { headers: headers }); // Corrigido: headers dentro de um objeto
            if (response !== null && response.data.Ok === true) {
                return response.data;
            } else {
                return response.data.Mensagem;
            }
        } else {
            const response = await axios.get(url);
            if (response !== null && response.data.Ok === true) {
                return response.data;
            } else {
                return response.data.Mensagem;
            }
        }
    } catch (error) {
        return error;
    }
}

export async function Post(url, body, headers) {
    if (headers !== null && headers !== undefined) {
        return await axios.post(url, body, headers).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.post(url, body).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}

export async function Put(url, body, headers) {
    if (headers !== null && headers !== undefined) {
        return await axios.put(url, body, headers).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.put(url, body).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}

export async function Delete(url, headers) {
    if (headers !== null && headers !== undefined) {
        return await axios.delete(url, headers).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.delete(url).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}