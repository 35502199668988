import { Get } from './BaseService';

const urlAreaNaoLogada = 'https://apipiano.gruposch.com.br/api/Configuracao';
const apiToken = 'kujdomveuapdnr';

export async function SalesEnabled () {
   
    const endpoint = '/HabilitarVendasNova?token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
    };


    return await Get(urlAreaNaoLogada + endpoint, headers);
}

export async function Consult (key) {
   
    const endpoint = '/Consultar?chave=' + key+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
    };
    

    return await Get(urlAreaNaoLogada + endpoint, headers);
}

