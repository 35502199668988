import { Get, GetComToken } from './BaseService';

const urlAreaNaoLogada = 'https://apipiano.gruposch.com.br/api/Arvore';
const urlAreaLogada = 'https://pianoapi.gruposch.com.br/api/Arvore';

export async function CompleteTree (languageId, email) {
const apiToken = 'gfdgfhtrytmjh';

    email = email.replace('@', '%40');
    const endpoint = '/ArvoreCompleta?idiomaId=' + languageId + "&emailUsuario=" + email  + '&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Authorization" : "Bearer " + localStorage.getItem('token')
    };

   return await GetComToken(urlAreaLogada + endpoint, headers);
}

export async function GetIndexTree (languageId) {
const apiToken = 'UpcXW3435oWhj9889kl4';

    const endpoint = '/ArvoreIndex?idiomaId=' + languageId + '&token=' + apiToken;

    const headers = { 
        'Accept': 'application/json',
    };

    return await Get(urlAreaNaoLogada + endpoint, headers);
}