import React from "react"
import Carousel from './Carousel'
import '../../assets/css/style.css'

import mosaico from '../../assets/img/MOSAICO-OUCA.png';
import youtube from '../../assets/img/YOUTUBE-OUCA.png';
import seta from '../../assets/img/SETA-BRANCO.png';

const images = {
    mosaico: mosaico,
    youtube: youtube,
    seta: seta
};

class QuartaSecao extends React.Component {

  render(){

        return (
            <>
            <div className='row'>
                <div className='col-md-5 col-sm-4 col-4' style={{padding: '0'}}>
                    <div className='container mosaico-position mosaico-new'>
                        <img src={images.mosaico} className='imgmosaico'/>
                        <div>
                            <a href="http://www.youtube.com/embed?listType=playlist&list=PLKgVajLr_Q-2-dM0qziej1SAJS_TJ6Hs1" target='_blank'>
                                <img src={images.youtube} className="imgyoutube" alt=""/>
                            </a>
                        </div>                        
                    </div>
                </div>
                <div className='col-md-1 col-sm-1 col-1 margin-top-depoimentos-seta'>
                    <img src={images.seta}/>
                </div>
                <div className='col-md-5 col-sm-6 col-6'>
                    <div className='container margin-top-depoimentos-escrita'>
                    <a href="https://youtube.com/playlist?list=PLKgVajLr_Q-2-dM0qziej1SAJS_TJ6Hs1&si=E6CwvwNp406k-AgO" target="_blank" style={{textDecoration: 'none'}}>CLIQUE AQUI E OUÇA DE QUEM JÁ FAZ PARTE</a>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default QuartaSecao;