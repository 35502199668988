import { Get, Post, GetComToken } from './BaseService';

const urlAreaNaoLogada = 'https://apipiano.gruposch.com.br/api/Usuario';
const urlAreaLogada = 'https://pianoapi.gruposch.com.br/api/Usuario';

export async function Register (nome, email, senha, cpf, celular, cupom, valor) {
    var apiToken = 'c73jfklo903jlkfll';

    const endpoint = '/Cadastro?nome='+ nome + '&email=' + email + '&celular=' + celular + '&cpf='+cpf+'&senha='+senha+'&valor='+valor+'&cupom='+cupom+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type",
        
    };

    return await GetComToken(urlAreaLogada + endpoint, headers);
}

export async function EmailAtivacao (email, nome, senha, tokenValidacao) {
    var apiToken = 'jkuolnhduuwjslf';

    const endpoint = '/EmailAtivacao?email='+ email + '&nome=' + nome + '&senha=' + senha + '&tokenValidacao=' + tokenValidacao + '&token=' + apiToken;
    
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(urlAreaNaoLogada + endpoint, headers);
}

export async function EnviarEmailSenha (email) {
    var apiToken = 'jkuolnhduuwjslf';
    const endpoint = '/EnviarEmailSenha?email='+ email+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(urlAreaNaoLogada + endpoint, headers);
}

export async function RequestValidation (request) {
    var apiToken = 'jkuolnhduuwjslf';
    const endpoint = '/RequestValidation?request='+ request+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };
    var data = await Get(urlAreaNaoLogada + endpoint, headers);
    return data;
}


export async function Consult(email, password) {
    var apiToken = 'jkuolnhduuwjslf';
    let verifiedEmail = toString(email);
    verifiedEmail = verifiedEmail.replace('@', '%40')

    const endpoint = '/Consultar?email=' + email + '&senha=' + password +'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
    };

    var teste = await Get(urlAreaNaoLogada + endpoint, headers);
    return teste;
}

export async function getUser (email, senha) {
const apiToken = 'gfdgfhtrytmjh';
    email = email.replace('@', '%40');
    const endpoint = '/getUser?email='+ email  + '&data=' + senha + '&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Authorization" : "Bearer " + localStorage.getItem('token')
    };

   return await GetComToken(urlAreaLogada + endpoint, headers);
}

export  async function updateUser(user) {
    const apiToken = 'gfdgfhtrytmjh';
    
       
    }
