import React from "react"
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import { Redirect } from "react-router-dom"
import { Navbar, Nav } from 'react-bootstrap';
import store from 'store'

//css
import '../../assets/css/style.css'
import { Suspense } from "react";

import logo from "../../assets/img/logo-sem-fundo-site.png"; // Importação estática


//components
// import CursoCompletoLogado from '../CursoCompletoLogado/index';
// import DicasDeDiscos from '../DicasDeDiscos/index';
// import Entrevistas from '../Entrevistas/index';
// import PlayAlongs from '../PlayAlongs/index';
// import Profile from '../Profile/index';

//lazy components
const CursoCompletoLogado = React.lazy(() => import('../CursoCompletoLogado/index'));
const DicasDeDiscos = React.lazy(() => import('../DicasDeDiscos/index'));
const Entrevistas = React.lazy(() => import('../Entrevistas/index'));
const PlayAlongs = React.lazy(() => import('../PlayAlongs/index'));
const Profile = React.lazy(() => import('../Profile/index'));


class IndexNavBar extends React.Component {

  handleLogout() {
    localStorage.clear()
    this.setState()
  }

  render() {
    return (
      <div>
        <Router>
          <Navbar collapseOnSelect id="home-menu" className={" fixed-top navbar-transparent mobile container-menu-ipad"} expand="lg" fixed="top">
            <Navbar.Brand id="navbar-brand">
              <img
                src={logo}
                className="mr-auto"
                to='/cursocompleto'
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav button-responsivo" />
            <Navbar.Collapse id="responsive-navbar-nav" className="menu">
              <Nav className="mr-auto ml-auto" style={{ marginLeft: '60px !important' }}>
                <div className='menu-container'>
                  <Link
                    className={"nav-link a-two active"}
                    href="#curso-completo"
                    style={{ width: 'auto' }}
                    to='/arealogada'
                  >

                    <p className='p-style'>CURSO COMPLETO</p>
                  </Link>
                </div>
                <div className='menu-container'>
                  <Link
                    className={"nav-link a-two"}
                    href="#novidades-menus"
                    style={{ width: 'auto' }}
                    to='/dicasdediscos'
                  >

                    <p className='p-style'>DICAS DE DISCOS</p>
                  </Link>
                </div>
                <div className='menu-container'>
                  <Link
                    className={"nav-link a-two"}
                    href="#depoimentos-menu"
                    style={{ width: 'auto' }}
                    to='/entrevistas'
                  >
                    <p className='p-style'>ENTREVISTAS</p>
                  </Link>
                </div>
                <div className='menu-container'>
                  <Link
                    className={"nav-link a-two"}
                    href="#conteudo-menu"
                    style={{ width: 'auto' }}
                    to='/playalongs'
                  >
                    <p className='p-style'>PLAY-ALONGS/COLABS</p>
                  </Link>
                </div>
                <div className='menu-container'>
                  <Link
                    className={"nav-link a-two"}
                    href="#conteudo-menu"
                    style={{ width: 'auto' }}
                    to='/profile'
                  >
                    <p className='p-style'>MEU PERFIL</p>
                  </Link>
                </div>
                <div className='menu-container-logout'>
                  <Nav.Link
                    className={"nav-link a-two"}
                    href="#conteudo-menu"
                    style={{ width: 'auto' }}
                    onClick={this.handleLogout.bind(this)}
                  >
                    <p className='p-style-login'>LOGOUT</p>
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Suspense fallback="">
            <Route exact path="/arealogada" component={CursoCompletoLogado} />
            <Route path="/dicasdediscos" component={DicasDeDiscos} />
            <Route path="/entrevistas" component={Entrevistas} />
            <Route path="/playalongs" component={PlayAlongs} />
            <Route path="/profile" component={Profile} />
          </Suspense>
        </Router>
        {/* <div>
<a href="https://api.whatsapp.com/send?phone=5519984458777&text=Gostaria de saber mais detalhes sobre o curso" target="_blank" className="whatsapp"></a>
</div> */}
      </div>
    );
  }
}

export default IndexNavBar;