import React from 'react';
import { Redirect } from "react-router-dom";
import Logado from './Logado';

class AreaLogada extends React.Component {

  isAuthenticated() {

    const token = localStorage.getItem('token');
    if (token != null && token.length > 15) {
      return true;
    } else {
      return false;
    }
  }

  render(){
    const isAlreadyAuthenticated = this.isAuthenticated();
    return(
      <>
        {!isAlreadyAuthenticated ? <Redirect to='/'/> : 
        (<Logado/>)}
      </>
    );
  }
}

export default AreaLogada;
