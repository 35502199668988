import React from "react";
import PrimeiraSecao from './PrimeiraSecao';
import SegundaSecao from './SegundaSecao';
import TerceiraSecao from './TerceiraSecao';
import QuartaSecao from './QuartaSecao';
import QuintaSecao from './QuintaSecao';

import { SalesEnabled, Consult } from '../../data/Service/ConfigurationService';

import '../../assets/css/style.css';

class CursoCompleto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            PurchaseEnabled: 'false',
            HabilitarVendaDireta: 'false',
            ArvoreDinamica: 'false',
            HabilitarPaginaVendas: 'false',
            TextoBotao: "ADQUIRIR O CURSO COMPLETO!"
        };

        this.getHabilitarVendas = this.getHabilitarVendas.bind(this);
        this._isMounted = false; // Adicione a variável de controle
    }

    async getHabilitarVendas() {
        if (!this._isMounted) return; // Verifique se o componente está montado

        try {
            let data = await SalesEnabled();
            if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
                if (this._isMounted) {
                    this.setState({
                        PurchaseEnabled: data.Dados[0].Valor
                    });
                }
            }

            data = await Consult("HABILITAR_VENDA_DIRETA");
            if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
                if (this._isMounted) {
                    this.setState({
                        HabilitarVendaDireta: data.Dados[0].Valor
                    });
                }
            }

            data = await Consult("BOTAO_COMPRAR");
            if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
                if (this._isMounted) {
                    this.setState({
                        TextoBotao: data.Dados[0].Valor
                    });
                }
            }

            data = await Consult("HABILITAR_PAGINA_COMPRAS");
            if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
                if (this._isMounted) {
                    this.setState({
                        HabilitarPaginaVendas: data.Dados[0].Valor
                    });
                }
            }

            data = await Consult("ARVORE_DINAMICA_NOVA");
            if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
                if (this._isMounted) {
                    this.setState({
                        ArvoreDinamica: data.Dados[0].Valor
                    });
                }
            }
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getHabilitarVendas();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { state } = this;

        return (
            <>
                <div id="parallax-world-of-ugg">
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=5519984458777&text=Gostaria de saber mais detalhes sobre o curso" target="_blank" className="whatsapp"></a>
                    </div>
                    <div className="parallax-curso-completo" id='conteudo-completo-h'>
                        <div className="d-flex row m-0 align-items-center" style={{ paddingTop: '8rem' }}>
                            <div className="col-12 self-align-left m-auto pb-5 pt-5 indexVideo" style={{ textAlign: 'center' }}>
                                <iframe className="pr-2 pl-2 indexVideoIFrame" src="https://www.youtube.com/embed/_5N3GAzx6Ow" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>

                        <div className='container container-quarta-secao'>
                            <QuintaSecao PurchaseEnable={state.PurchaseEnabled} HabilitarVendaDireta={state.HabilitarVendaDireta} HabilitarPaginaVendas={state.HabilitarPaginaVendas} TextoBotao={this.state.TextoBotao} />
                        </div>
                        <div className='container container-primeira-secao mt-5'>
                            <PrimeiraSecao ArvoreDinamica={state.ArvoreDinamica} />
                        </div>
                        <div className='container container-segunda-secao'>
                            <div className='container content-completo text-curso-completo custom-width' style={{ width: '715px' }}>
                                O QUE INCLUI O CURSO COMPLETO
                            </div>
                            <div className='container container-terceira-secao text-curso'>
                                <SegundaSecao />
                                <TerceiraSecao />
                            </div>
                            <div className='container container-quarta-secao'>
                                <QuartaSecao />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CursoCompleto;